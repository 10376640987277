import React, {FC, useEffect, useState} from "react";

export type ContactFormProps = {}

export const ContactForm: FC<ContactFormProps> = () => {
    const successMessage = "Thank you for your message, we will be in touch soon.";

    const [submitted, setSubmitted] = useState(false)
    useEffect(() => {
        if ( window.location.search.includes('success=true') ) {
            setSubmitted(true);
        }
    }, []);

    return (
        <form action="/?success=true" name="contact" data-netlify="true" className="form" method="POST">
            <input type="hidden" name="subject" value="Nouveau message de ton site web" />
            <input type="hidden" name="form-name" value="contact" />

            <label htmlFor="name">Name</label>
            <input required id="name" name="name" type="text"/>
            <label htmlFor="email">Email address</label>
            <input required id="email" name="email" type="email"/>
            <label htmlFor="message">Message</label>
            <textarea required id="message" name="message"/>
            {
                submitted
                    ? <p>{successMessage}</p>
                    : <button className="submitButton"
                              type="submit"
                    >Submit</button>
            }
        </form>
    )
};

export default ContactForm;
