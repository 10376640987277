import React, {useState} from 'react';
import './App.css';
import ContactForm from "./ContactForm";


function App() {
    const [isOpenModal, setOpenModal] = useState(false)
    const xMark = <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"/>
    </svg>;
    const codeOpen = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 24">
        <path d="M24 10.111v1.1111-1 3.948zm-16 3.848l-5.64-2.783 5.64-2.79v-2.223l-8 3.948v2.131l8 3.947v-2.23zm7.047-10.783h-2.078l-4.011"/></svg>
    const codeClose = <svg xmlns="http://www.w3.org/2000/svg" viewBox="8 0 24 24">
        <path d="M24 10.935v2.131l-8 3.947v-2.23l5.64-2.783-5.64-2.79v-2.223l8 3.948zm-16 3.947v-2.23zm7.047-10.783h-2.078l-4.011 16h2.073l4.016-16z"></path></svg>

    return (
        <div className="App" >
            <div onClick={() => isOpenModal && setOpenModal(false)}>
                <img src="coffee.gif" alt="coffee animation"/>
                <div className="heading">
                    <div className="heading-deco-1">{codeOpen}</div>
                    <h1 className="title">Work in progress</h1>
                    <div className="heading-deco-2">{codeClose}</div>
                </div>
                <p>Patience, we are currently in the process of making this website more beautiful. Grab a coffee and come back later or <strong
                    onClick={() => setOpenModal(true)}>contact us</strong> if you can't wait to get in touch.</p>
                <p>By the way, in the meantime you can also check out what we're doing over at <a href="https://staffwerke.de">Staffwerke</a>.</p>
                <p className="credits">
                    Graphics by <a href="https://dribbble.com/j_brenna" rel="noreferrer">Jessica Brennan</a><br/>
                    This website belongs to <a href="https://www.societe.com/societe/staffworks-894308055.html">Staffworks</a><br/>
                </p>
            </div>
            {
                isOpenModal && <div className="card">
                    <div onClick={() => setOpenModal(false)} className="close">{xMark}</div>
                    <ContactForm/>
                </div>
            }
        </div>
    );
}

export default App;
